import mapsData from '@/data/map.json'
import { transliterate } from 'transliteration'
import { MapDataTypes } from '@/data/mapType.data'

export function search(
    options: {
        val: string
        type: string
        area: string
    },
    limit: number | null = null,
) {
    const { val, type, area } = options
    if (!val && !type && !area) return []

    const transliteratedVal = val ? transliterate(val.toLowerCase()) : ''
    const transliteratedType = type ? transliterate(type.toLowerCase()) : ''
    const transliteratedArea = area ? transliterate(area.toLowerCase()) : ''
    let results = mapsData.features.filter((element) => {
        const elementVal = transliterate(element.value.toLowerCase())
        const elementType = element.attributes?.type
            ? transliterate(element.attributes.type.toLowerCase())
            : ''
        const elementArea = element.attributes?.district
            ? transliterate(element.attributes.district.toLowerCase())
            : ''

        return (
            (!val || elementVal.startsWith(transliteratedVal)) &&
            (!type || elementType.includes(transliteratedType)) &&
            (!area || elementArea.includes(transliteratedArea))
        )
    })

    // If a limit is specified, slice the results to that limit.
    if (limit !== null && !isNaN(limit)) {
        results = results.slice(0, limit)
    }
    return results
}
export function getDistinctDistricts() {
    const districts = new Set()
    mapsData.features.forEach((feature) => {
        if (feature.attributes.district) {
            districts.add(feature.attributes.district)
        }
    })
    return Array.from(districts)
}

export function getMapTypes() {
    return MapDataTypes
}
