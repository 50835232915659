'use client'
import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, AutoComplete, Select } from 'antd'
import BaseContainer from '@/components/common/base-container/BaseContainer'
import Heading from '@/components/common/heading/Heading'
import ButtonPrimary from '@/components/common/button-primary/SimpleButton'
import styles from './interactive.module.scss'
import MapView from '@/components/common/map-view/MapView'
import {
    search as searchMap,
    getMapTypes,
    getDistinctDistricts,
} from '@/lib/search-map.service'
import { useDebounce } from '@/utils/useDebounce'
import { HeadingType } from '@/types/mixed.types'

// Type Definitions
interface Center {
    lat: number
    lng: number
}

interface InteractiveMapProps {
    isBgShow?: boolean
    cls?: string
    title?: string
    allCategory: string
    resetFilters: string
    placeholder: string
    isHeadingShow?: boolean
}

// Coordinates of Yakutsk
const yakutskCenter = [
    {
        lat: 62.035452,
        lng: 129.675475,
    },
]
const InteractiveMap: React.FC<InteractiveMapProps> = ({
    isBgShow,
    cls,
    title,
    allCategory,
    resetFilters,
    isHeadingShow,
    placeholder,
}) => {
    const [center, setCenter] = useState<Center[]>(yakutskCenter)
    const [searchResults, setSearchResults] = useState<MapItemType[]>([])
    const [inputValue, setInputValue] = useState<string>('')
    const debouncedSearchTerm = useDebounce(inputValue, 300)
    const [zoom, setZoom] = useState<number>(16)
    const [selectedType, setSelectedType] = useState<string>('')
    const [area, setArea] = useState<string>('')
    const mapTypes = getMapTypes()
    const [form] = Form.useForm()

    useEffect(() => {
        if (debouncedSearchTerm) {
            const results = searchMap(
                {
                    type: selectedType,
                    val: debouncedSearchTerm,
                    area: area,
                },
                10,
            )
            setSearchResults(results as [])
        }
    }, [debouncedSearchTerm, selectedType, area])

    const handleSearchChange = useCallback((value: string) => {
        setInputValue(value)
    }, [])

    const handleSelectChange = useCallback(
        (value: string) => {
            const selected = searchResults.find((el) => el.value === value)
            if (selected) {
                setSearchResults([selected])
                setZoom(16)
                setCenter([{ lat: selected.attributes.lat, lng: selected.attributes.lng }])
            }
        },
        [searchResults],
    )

    const handleSelectTypeChange = useCallback(
        (value: string) => {
            setSelectedType(value)
            const results = searchMap({
                type: value,
                val: debouncedSearchTerm,
                area: area,
            })
            const validResults = results.filter(
                (result) =>
                    result.attributes.lat !== null &&
                    result.attributes.lng !== null,
            )
            setSearchResults(validResults as [])
            setCenter(
                results.length
                    ? [
                          ...results.map((el: any) => ({
                              lat: el.attributes.lat,
                              lng: el.attributes.lng,
                          })),
                      ]
                    : yakutskCenter,
            )
            setZoom(4)
        },
        [debouncedSearchTerm, area],
    )

    const onAreaChange = useCallback(
        (value: string) => {
            setArea(value)
            const results = searchMap({
                type: selectedType,
                val: debouncedSearchTerm,
                area: value,
            })
            const validResults = results.filter(
                (result) =>
                    result.attributes.lat !== null &&
                    result.attributes.lng !== null,
            )
            setSearchResults(validResults as [])
            setCenter(
                results.length
                    ? [
                          ...results.map((el: any) => ({
                              lat: el.attributes.lat,
                              lng: el.attributes.lng,
                          })),
                      ]
                    : yakutskCenter,
            )
            setZoom(4)
        },
        [debouncedSearchTerm, selectedType],
    )
    const resetAll = useCallback(() => {
        setInputValue('')
        setSelectedType('')
        setSearchResults([])
        setCenter(yakutskCenter)
        setArea('')
        setZoom(16)
        form.resetFields()
    }, [form])

    return (
        <div className={`${styles.interactive_wrapper} ${cls}`}>
            {isBgShow && <div className={styles.section_bg} />}
            <BaseContainer>
                {(title && isHeadingShow) && <Heading type={HeadingType.h2} text={title} />}
                <Form form={form}>
                    <Row gutter={24}>
                        <Col lg={7} xs={24}>
                            <div className={styles.map_left}>
                                <Form.Item name="value">
                                    <AutoComplete
                                        options={searchResults.map((el) => ({
                                            value: el.value,
                                        }))}
                                        onSearch={handleSearchChange}
                                        onSelect={handleSelectChange}
                                        placeholder={placeholder}
                                    />
                                </Form.Item>
                                <Form.Item name="type">
                                    <Select
                                        value={selectedType}
                                        options={mapTypes.map((el) => ({
                                            value: el,
                                        }))}
                                        allowClear
                                        onClear={() => {
                                            setSelectedType('')
                                            form.setFieldsValue({ type: '' })
                                        }}
                                        onSelect={handleSelectTypeChange}
                                        placeholder={allCategory}
                                    />
                                </Form.Item>

                                <Form.Item name="area">
                                    <Select
                                        options={getDistinctDistricts().map(
                                            (el) => ({
                                                value: el,
                                            }),
                                        )}
                                        showSearch={false}
                                        onClear={() => {
                                            setArea('')
                                            form.setFieldsValue({ area: '' })
                                        }}
                                        allowClear
                                        onSelect={onAreaChange}
                                        placeholder="Все области"
                                    />
                                </Form.Item>

                                <div className={styles.btn_list}>
                                    <ButtonPrimary
                                        block
                                        text={resetFilters}
                                        size="large"
                                        onClick={resetAll}
                                    />
                                    {searchResults.length > 0 && (
                                        <span className={styles.result}>
                                            Found {searchResults.length} results
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col lg={17} xs={24}>
                            <MapView
                                mapItems={searchResults}
                                zoom={zoom}
                                center={center}
                                lat={center[0].lat}
                                lng={center[0].lng}
                            />
                        </Col>
                    </Row>
                </Form>
            </BaseContainer>
        </div>
    )
}

export default InteractiveMap
