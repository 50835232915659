import { Button } from "antd";
import { ButtonType, ButtonSize } from "antd/es/button";
import React from "react";
import styles from "./button.module.scss";
type ButtonPrimaryType = {
  text?: string;
  type?: ButtonType;
  size?: ButtonSize;
  icon?: React.ReactNode;
  block?: boolean;
  href?: string;
  children?: React.ReactNode;
  [key: string]: any;
};
const SimpleButton: React.FC<ButtonPrimaryType> = ({
  text,
  type = "default",
  size = "small",
  block,
  children,
  icon,
  href,
  ...props
}) => {
  return (
    <div className={styles.btn_wrapper}>
      <Button
        target={href && "_blank"}
        rel={href && "noopener noreferrer"}
        href={href}
        className={`${styles.btn} ${
          block ? styles.btn_block : styles.btn_default
        } `}
        icon={icon}
        type={type}
        size={size}
        {...props}
      >
        {text}
        {children}
      </Button>
    </div>
  );
};

export default SimpleButton;
