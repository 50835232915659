export const MapDataTypes = [
    'Исторический',
    'Культурный',
    'Познавательный',
    'Место отдыха',
    'Природный',
    'Культурно-развлекательный',
    'Спортивный',
    'Развлекательный',
    'Музей',
    'Мемориальный',
    'Памятник истории'
]
