'use client'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'

// Define the props for your component
interface MapContainerProps {
    zoom: number
    mapItems?: MapItemType[] | []
    lat: number
    lng: number
    center?: { lat: number; lng: number }[]
}

// This is the styling for the map container. Adjust as necessary.
const containerStyle = {
    width: '100%',
}

const MapContainer: React.FC<MapContainerProps> = ({
    mapItems,
    zoom,
    lat,
    lng,
    center,
}) => {
    const items = mapItems?.map((item) => item.attributes)
    const lngData = items?.[0]?.lng || lng
    const latData = items?.[0]?.lat || lat
    const [selectedItem, setSelectedItem] = useState<MapAttributesType | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false)

    // Define the initial state of the map using props
    const mapState: any = {
        center: [center?.[0]?.lat || latData, center?.[0]?.lng || lngData],
        zoom,
        modules: ['geoObject.addon.balloon', 'geoObject.addon.hint'],
    }

    // // Effect to open modal when item changes
    // useEffect(() => {
    //     if (item) {
    //         setIsModalVisible(true) // Open the modal when item changes
    //     }
    // }, [item]) // Dependency array includes item to react on its change
    const onPlacemarkClick = (item: MapAttributesType) => {
        setSelectedItem(item); // Update the selected item
        setIsModalVisible(true); // Show the modal
    };
    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    // Prepare the balloon content as HTML
    function prepareBalloonContent(item?: MapAttributesType) {
        if (!item) {
            return ''
        }
        const balloonContent = `
          <div>
            <h3>${item.title}</h3>
            <p>${item.description}</p>
            <p><strong>Address:</strong> ${item.address}</p>
            ${item.availability ? `<p><strong>Availability:</strong> ${item.availability}</p>` : ''}
            ${item.time_work ? `<p><strong>Working Hours:</strong> ${item.time_work}</p>` : ''}
            ${item.barrier_free ? `<p><strong>Accessibility:</strong> ${item.barrier_free}</p>` : ''}
            ${item.price ? `<p><strong>Price:</strong> ${item.price}</p>` : ''}
            ${item.note ? `<p><strong>Note:</strong> ${item.note}</p>` : ''}
          </div>
        `
        return balloonContent
    }

    return (
        <YMaps>
            <div style={containerStyle}>
                <Map state={mapState} width="100%" height="560px">
                    {/* Render the placemark on the map */}
                    {mapItems?.map((item, index) => (
                        <Placemark
                            key={index}
                            geometry={[item.attributes.lat, item.attributes.lng]}
                            properties={{
                                balloonContent: prepareBalloonContent(item.attributes),
                                hintContent: item.attributes.title,
                            }}
                            onClick={() => onPlacemarkClick(item.attributes)} // Update to call the new function
                            options={{
                                openBalloonOnClick: true,
                                hideIconOnBalloonOpen: false,
                            }}
                        />
                    ))}
                </Map>
            </div>
            {selectedItem && ( // Use selectedItem to populate the modal
                <Modal
                    title={selectedItem.title}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <h3>{selectedItem.district}</h3>
                    <p>{selectedItem.description}</p>
                    {selectedItem.address && (
                        <p>
                            <strong>Address:</strong> {selectedItem.address}
                        </p>
                    )}
                    {selectedItem.availability && (
                        <p>
                            <strong>Availability:</strong> {selectedItem.availability}
                        </p>
                    )}
                    {selectedItem.time_work && (
                        <p>
                            <strong>Working Hours:</strong> {selectedItem.time_work}
                        </p>
                    )}
                    {selectedItem.barrier_free && (
                        <p>
                            <strong>Accessibility:</strong> {selectedItem.barrier_free}
                        </p>
                    )}
                    {selectedItem.price && (
                        <p>
                            <strong>Price:</strong> {selectedItem.price}
                        </p>
                    )}
                    {selectedItem.note && (
                        <p>
                            <strong>Note:</strong> {selectedItem.note}
                        </p>
                    )}
                </Modal>
            )}
        </YMaps>
    )
}

export default React.memo(MapContainer)
